import { Grid, Card, Description, Modal, Button } from "@geist-ui/react";
import { useState, useEffect } from "react";
import { MapPin } from "@geist-ui/react-icons";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
const activities = [
  { kode: "CYCLING", dk: "Cyklende" },
  { kode: "IN_PASSENGER_VEHICLE", dk: "Passager i bil" },
  { kode: "MOTORCYCLING", dk: "Kører på motorcykel" },
  { kode: "WALKING", dk: "Gående" },
  { kode: "RUNNING", dk: "Løbende" },
  { kode: "IN_BUS", dk: "Kører i bus" },
  { kode: "STILL", dk: "Står stille" },
  { kode: "IN_FERRY", dk: "Sejler med en færge" },
  { kode: "IN_TRAIN", dk: "Kører med tog" },
  { kode: "SKIING", dk: "Står på ski" },
  { kode: "IN_SUBWAY", dk: "Kører med metro" },
  { kode: "IN_TRAM", dk: "Kører med sporvogn" },
  { kode: "SAILING", dk: "Sejler" },
  { kode: "FLYING", dk: "Flyver" },
  { kode: "IN_VEHICLE", dk: "Kører bil" },
];
const ActivityCard = (props) => {
  const [visible, setVisible] = useState(false);
  const [activityLine, setActivityLine] = useState(null);
  const [middleCenter, setMiddleCenter] = useState(null);
  const [zoom, setZoom] = useState(1);
  var segment = props.activity;
  var type = activities.find(
    (activity) => activity.kode == segment.activityType
  );
  var startdate = new Date(parseInt(segment.duration.startTimestampMs));
  var starttid = startdate.toLocaleTimeString();
  var sluttid = new Date(
    parseInt(segment.duration.endTimestampMs)
  ).toLocaleTimeString();

  const averageOfVectors = (vec1, vec2) => {
    return [(vec1[0] + vec2[0]) / 2, (vec1[1] + vec2[1]) / 2];
  };

  var startlocation = [
    segment.startLocation.latitudeE7 / 10000000,
    segment.startLocation.longitudeE7 / 10000000,
  ];
  var endlocation = [
    segment.endLocation.latitudeE7 / 10000000,
    segment.endLocation.longitudeE7 / 10000000,
  ];

  var waypointsArray =
    segment.waypointPath &&
    segment.waypointPath.waypoints.map((waypoint) => {
      var lat = waypoint.latE7 / 10000000;
      var long = waypoint.lngE7 / 10000000;

      return [lat, long];
    });

  var path =
    waypointsArray != undefined
      ? [startlocation].concat(waypointsArray).concat([endlocation])
      : [startlocation, endlocation];

  const lineOptions = { color: "red" };
  useEffect(() => {
    setActivityLine(<Polyline pathOptions={lineOptions} positions={path} />);
    setMiddleCenter(averageOfVectors(startlocation, endlocation))
    setZoom(14)
  }, []);

  const closeHandler = () => setVisible(false);
  return (
    <>
      <Modal visible={visible} onClose={closeHandler}>
        <Modal.Title>Kort</Modal.Title>
        <Modal.Content>
          <div style={{ height: "400px", display: "flex" }}>
            <MapContainer
              style={{ flexGrow: 1, borderRadius: "5px" }}
              zoom={zoom}
              center={middleCenter}
              scrollWheelZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {activityLine}
            </MapContainer>
          </div>
        </Modal.Content>
      </Modal>
      <Grid style={{ minWidth: "180px" }} xs={6} sm={6} md={6} lg={6}>
        <Card width="100%">
          <Card.Content>
            <Grid.Container direction="row" gap={2}>
              <Grid>
                <Description
                  title="Aktivitet"
                  content={type === undefined ? segment.activityType : type.dk}
                />
              </Grid>
              <Grid>
                <Description
                  title="Distance"
                  content={segment.distance + "m"}
                />
              </Grid>

              <Grid>
                <Description title="Starttidspunkt" content={starttid} />
              </Grid>
              <Grid>
                <Description title="Sluttidspunkt" content={sluttid} />
              </Grid>
              <Grid>
                <Description
                  title="Dato"
                  content={startdate.toLocaleDateString()}
                />
              </Grid>
            </Grid.Container>
          </Card.Content>
          <Card.Footer>
            <Button
              width="100%"
              icon={<MapPin />}
              onClick={() => setVisible(true)}
            >
              Vis Kort
            </Button>
          </Card.Footer>
        </Card>
      </Grid>
    </>
  );
};

export default ActivityCard;
