import React, { useState } from "react";
import { GeistProvider, CssBaseline, Button } from "@geist-ui/react";
import { Moon, Sun, Home } from "@geist-ui/react-icons";
import "./App.css";
import HomePage from "./components/HomePage";
import { Routes, Route, useNavigate } from "react-router-dom";
import SubmittedPage from "./components/SubmittedPage";
function App() {
  let navigate = useNavigate();
  const [themeType, setThemeType] = useState(false);
  const RedirectToHome = () => {
    sessionStorage.setItem("mapData", "INVALID");

    navigate("/");
  };
  return (
    <GeistProvider themeType={themeType ? "dark" : "light"}>
      <Button
        iconRight={<Home />}
        auto
        scale={2 / 3}
        px={0.6}
        onClick={RedirectToHome}
        className="fixed"
      />
      <Button
        iconRight={themeType ? <Moon /> : <Sun />}
        auto
        scale={2 / 3}
        px={0.6}
        onClick={() => setThemeType(!themeType)}
        className="fixed2"
      />
      <CssBaseline>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/submitted" element={<SubmittedPage />} />
        </Routes>
      </CssBaseline>
    </GeistProvider>
  );
}
export default App;
